import { Service } from '@/services/service'

const PATH = 'whatsapp/sessions/'

class SessionsService extends Service {
  getCurrentUserSessionTotal (params = {}) {
    return this.get(PATH + 'current-totals', params)
  }

  getConversations (params = {}) {
    return this.get(PATH + 'conversations', params)
  }

  geNewConversations (params = {}) {
    return this.get(PATH + 'new-conversations', params)
  }

  getConversationsOpen (params = {}) {
    return this.get(PATH + 'conversations-open', params)
  }

  getConversationsPending (params = {}) {
    return this.get(PATH + 'conversations-pending', params)
  }

  getConversationsOpenPending (params = {}) {
    return this.get(PATH + 'conversations-open-pending', params)
  }

  getConversationsClosed (params = {}) {
    return this.get(PATH + 'conversations-closed', params)
  }

  getMessages (params = {}) {
    return this.get(PATH + 'messages', params)
  }

  sendMessage (params = {}) {
    return this.post(PATH + 'send-message', params)
  }

  getLastestSessionDate (params = {}) {
    return this.post(PATH + 'last-session-date', params)
  }

  getLastestSessionExpirationDate (params = {}) {
    return this.post(PATH + 'last-session-expiration-date', params)
  }

  getLastestCSWExpirationDate (params = {}) {
    return this.post(PATH + 'last-csw-expiration-date', params)
  }

  getSessionAgentFromMsisdns (params = {}) {
    return this.post(PATH + 'session-agent-from-msisdns', params)
  }

  updateSessionAgentFromMsisdns (params = {}) {
    return this.post(PATH + 'update-session-agent-from-msisdns', params)
  }

  closeConversation (params = {}) {
    return this.post(PATH + 'close-conversation', params)
  }

  openConversation (params = {}) {
    return this.post(PATH + 'open-conversation', params)
  }

  addContactToBlackList (params = {}) {
    return this.post(PATH + 'add-to-blacklist', params)
  }

  removeContactFromBlackList (params = {}) {
    return this.post(PATH + 'remove-from-blacklist', params)
  }

  getBlockListFromMsisdnUserAndClient (params = {}) {
    return this.post(PATH + 'block-list-msisdn-user-client', params)
  }

  getCountNotExpiredConversations (params) {
    return this.get(PATH + 'count-not-expired-conversations', params)
  }
}

export default new SessionsService()
